<template>
  <a-layout class="main-container">
    <home-nav />
    <img
      src="@/assets/intelligence/intelligence-banner.png"
      alt=""
      class="banner-img"
    />
    <div class="content-container">
      <div class="module orange">
        <div class="module-name">美育专家</div>
        <div class="module-name-en">Aesthetic Education Expert</div>
        <div class="module-divider"></div>
        <div class="module-dec">汇聚行业顶级少儿美育专家、教育学者</div>
      </div>
      <div class="expert-container">
        <a-carousel>
          <div class="expert-page" v-for="(page, pageInd) in 2" :key="pageInd">
            <div class="expert-item" v-for="(item, index) in 12" :key="index">
              <a-popover
                :placement="
                  index % 6 === 0
                    ? 'bottomLeft'
                    : (index + 1) % 6 === 0
                    ? 'bottomRight'
                    : 'bottom'
                "
                :overlayStyle="{ width: '344px' }"
                :getPopupContainer="
                  triggerNode => {
                    return triggerNode.parentNode;
                  }
                "
              >
                <div class="item-img">
                  <img src="@/assets/intelligence/auth.png" alt="" />
                </div>
                <template slot="content">
                  <div class="person-info">
                    <div class="person-info-name">龙念南</div>
                    <div class="person-info-job">
                      中国少年儿童造型艺术学会创办人之一
                    </div>
                    <div class="person-experience-label">
                      履历
                    </div>
                    <div class="person-experience-content">
                      中国少年儿童造型艺术学会创办人之一，中国美术家协会少儿艺委常务理事，中国美术家协会会员，全国义务教育《美术》教材编委，悦少儿美术馆馆长阻理，少儿美术教育专家
                    </div>
                  </div>
                </template>
              </a-popover>
              <div class="item-name">王铁柱</div>
              <div class="item-job-title">造型艺术学会秘书长</div>
            </div>
          </div>
        </a-carousel>
      </div>
      <div class="module blue">
        <div class="module-name">合作单位</div>
        <div class="module-name-en">The cooperation unit</div>
        <div class="module-divider"></div>
        <div class="module-dec">
          联合社会各界力量，为儿童美育事业发展增添动力
        </div>
      </div>
      <div class="unit-tab">
        <div class="tab-container">
          <div
            class="tab-item"
            :class="unitType === unit ? 'active-tab' : ''"
            v-for="(unit, ind) in unitTypeList"
            :key="ind"
            :id="`unitTab${ind}`"
            @click="selectUnitTab(unit, ind)"
          >
            {{ unit }}
            <div
              class="tab-line"
              v-if="unitType === unit"
              :id="`unitTabLine${ind}`"
            ></div>
          </div>
        </div>
      </div>
      <div class="unit-container">
        <a-carousel>
          <div class="unit-page" v-for="(page, pageInd) in 2" :key="pageInd">
            <div class="unit-item" v-for="(item, index) in 10" :key="index">
              <div class="item-img">
                <img src="@/assets/intelligence/unit-img.png" alt="" />
              </div>
              <div class="item-name">
                {{
                  unitType === "机构"
                    ? "老鹰画室"
                    : unitType === "院校"
                    ? "中央美术学院"
                    : "京东方集团"
                }}
              </div>
            </div>
          </div>
        </a-carousel>
      </div>
      <div class="module yellow">
        <div class="module-name">垂直领域艺术工作者</div>
        <div class="module-name-en">Vertical field artists</div>
        <div class="module-divider"></div>
        <div class="module-dec">
          联合社会各界力量，为儿童美育事业发展增添动力
        </div>
      </div>
      <div class="artist-tab">
        <div class="tab-container">
          <div
            class="tab-item"
            v-for="(type, ind) in artistsTypeList"
            :key="ind"
            @click="selectArtistsType(type, ind)"
            :id="`tab${ind}`"
            :style="{ color: fetchArtistTypeColor(type) }"
          >
            {{ type }}
            <div
              class="tab-line"
              v-if="artistsType === type"
              :id="`tabLine${ind}`"
            ></div>
          </div>
        </div>
      </div>
      <div class="artist-page">
        <div class="artist-item" v-for="(item, index) in 12" :key="index">
          <a-popover
            :placement="
              index % 6 === 0
                ? 'bottomLeft'
                : (index + 1) % 6 === 0
                ? 'bottomRight'
                : 'bottom'
            "
            :overlayStyle="{ width: '344px' }"
            :getPopupContainer="
              triggerNode => {
                return triggerNode.parentNode;
              }
            "
          >
            <div class="item-img">
              <img src="@/assets/intelligence/auth.png" alt="" />
            </div>
            <template slot="content">
              <div class="person-info">
                <div class="person-info-name">龙念南</div>
                <div class="person-info-job">
                  中国少年儿童造型艺术学会创办人之一
                </div>
                <div class="person-experience-label">
                  履历
                </div>
                <div class="person-experience-content">
                  中国少年儿童造型艺术学会创办人之一，中国美术家协会少儿艺委常务理事，中国美术家协会会员，全国义务教育《美术》教材编委，悦少儿美术馆馆长阻理，少儿美术教育专家
                </div>
              </div>
            </template>
          </a-popover>
          <div class="item-name">王铁柱</div>
          <div class="item-job-title">造型艺术学会秘书长</div>
        </div>
      </div>
    </div>

    <div class="footer">
      <div class="footer-container">
        <p class="footer-top">
          关于学会
          <span>联系我们</span>
          <span>官方邮箱：caca_1992@163.com</span>
          <span>地址：北京市东城区灯市口大街100号210室</span>
        </p>
        <p class="footer-bottom">
          © 2011~2021 中国少年儿童造型艺术学会 | 网站备案号:京ICP备06005221号-1
        </p>
        <img
          src="@/assets/intelligence/icon-logo.png"
          alt=""
          class="icon-logo"
        />
      </div>
    </div>

    <div class="side-operation">
      <img
        src="@/assets/intelligence/icon-close.png"
        alt=""
        class="side-icon"
        @click="onCloseQrCode"
        v-if="isShowQrCode"
      />
      <img
        src="@/assets/intelligence/icon-scan.png"
        alt=""
        class="side-icon"
        v-if="!isShowQrCode"
        @click="showQrCode"
      />
      <img
        src="@/assets/intelligence/icon-share.png"
        alt=""
        class="side-icon"
      />
      <img
        src="@/assets/intelligence/icon-top.png"
        alt=""
        class="side-icon"
        @click="onScrollTop"
      />

      <div class="qrcode" v-if="isShowQrCode">
        <div class="qrcode-container">
          <div class="qrcode-top">
            <div class="qrcode-item">
              <div class="qrcode-item-name">学会公众号</div>
              <img src="@/assets/intelligence/qrcode1.png" alt="" />
            </div>
            <div class="qrcode-item">
              <div class="qrcode-item-name">美创汇公众号</div>
              <img src="@/assets/intelligence/qrcode2.png" alt="" />
            </div>
          </div>
          <div class="qrcode-tip">扫码关注公众号，美育动态尽在掌握</div>
          <div class="qrcode-arrow"></div>
        </div>
      </div>
    </div>
  </a-layout>
</template>

<script>
import HomeNav from "@/components/HomeNav.vue";

export default {
  components: { HomeNav },
  data() {
    return {
      isShowQrCode: false,
      visible: false,
      unitTypeList: ["机构", "院校", "企业"],
      unitType: "机构",
      artistsTypeList: ["绘画", "书法", "手工雕塑", "数字艺术", "创意设计"],
      artistsType: "绘画"
    };
  },
  methods: {
    // 回到顶部
    onScrollTop() {
      window.scrollTo({
        left: 0,
        top: 0,
        behavior: "smooth"
      });
    },
    showQrCode() {
      this.visible = true;
      this.isShowQrCode = !this.isShowQrCode;
    },
    onCloseQrCode() {
      this.visible = false;
      this.isShowQrCode = !this.isShowQrCode;
    },
    selectUnitTab(type, ind) {
      this.unitType = type;
      const width = document.getElementById(`unitTab${ind}`).offsetWidth;
      this.$nextTick(() => {
        document.getElementById(`unitTabLine${ind}`).style.width = `${width}px`;
      });
    },
    selectArtistsType(type, ind) {
      this.artistsType = type;
      const width = document.getElementById(`tab${ind}`).offsetWidth;
      this.$nextTick(() => {
        document.getElementById(`tabLine${ind}`).style.width = `${width}px`;
      });
    },
    fetchArtistTypeColor(key) {
      const color = {
        绘画: "#005DE2",
        书法: "#8E580F",
        手工雕塑: "#FF8807",
        数字艺术: "#FE47AC",
        创意设计: "#06B24A"
      };
      return color[key] || "";
    }
  }
};
</script>

<style scoped lang="less">
.main-container {
  min-height: 100vh;
  background-color: #faf9fa;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 72px;
  .banner-img {
    width: 100%;
    object-fit: contain;
  }
  .content-container {
    width: 1150px;
    .module {
      box-sizing: border-box;
      padding: 30px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      .module-name {
        font-weight: 600;
        font-size: 24px;
        color: rgba(0, 0, 0, 0.85);
      }
      .module-name-en {
        font-weight: 600;
        font-size: 18px;
        color: rgba(0, 0, 0, 0.85);
      }
      .module-divider {
        margin-bottom: 1px;
        width: 250px;
        height: 4px;
        background: #261401;
      }
      .module-dec {
        font-weight: 300;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.85);
      }
    }
    .orange {
      background-color: #ff8807;
    }
    .blue {
      background-color: #005de2;
    }
    .yellow {
      background-color: #fece00;
    }
    .expert-container {
      box-sizing: border-box;
      padding: 58px 73px 108px 73px;
      .expert-page {
        display: grid !important;
        grid-template-columns: repeat(6, 1fr);
        grid-row-gap: 52px;
        grid-column-gap: 40px;
        .expert-item {
          width: 124px;
          display: flex;
          flex-direction: column;
          align-items: center;
          .item-img {
            width: 124px;
            height: 124px;
            background: #d8d8d8;
            border: 1px solid #979797;
            border-radius: 124px;
            img {
              width: 100%;
              height: 100%;
              border-radius: 124px;
            }
          }
          .item-img:hover {
            border: 2px solid #ff8807;
          }
          .item-name {
            margin-top: 12px;
            margin-bottom: 4px;
            font-weight: 600;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.85);
            letter-spacing: 2px;
          }
          .item-job-title {
            width: 100%;
            font-weight: 300;
            font-size: 10px;
            color: rgba(0, 0, 0, 0.85);
            letter-spacing: 1px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: center;
          }
        }
      }
      /deep/ .ant-carousel .slick-dots-bottom {
        height: 12px;
        bottom: -42px;
      }
      /deep/ .ant-carousel .slick-dots li button {
        height: 12px;
        width: 12px;
        border-radius: 12px;
        background: #d8d8d8;
        opacity: 1;
      }
      /deep/ .ant-carousel .slick-dots li.slick-active button {
        background-color: #ff8807;
      }
    }
    .unit-tab {
      margin-top: 36px;
      margin-bottom: 28px;
      display: flex;
      justify-content: center;
      .tab-container {
        display: flex;
        border-bottom: 2px solid #979797;
        .tab-item {
          font-weight: 600;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.85);
          letter-spacing: 2px;
          cursor: pointer;
          position: relative;
          margin-left: 24px;
          .tab-line {
            position: absolute;
            bottom: -2px;
            left: 0;
            width: 32px;
            height: 2px;
            background-color: #ff8807;
            z-index: 10;
          }
        }
        .tab-item:first-child {
          margin: 0;
        }
        .active-tab {
          color: #ff8000;
        }
      }
    }
    .unit-container {
      box-sizing: border-box;
      padding: 0 65px 108px 65px;
      .unit-page {
        display: grid !important;
        grid-template-columns: repeat(5, 1fr);
        grid-row-gap: 40px;
        grid-column-gap: 20px;
        .unit-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          .item-img {
            width: 188px;
            height: 104px;
            background-color: #ffffff;
            box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.06);
            margin-bottom: 12px;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
            }
          }
          .item-name {
            width: 188px;
            font-weight: 600;
            font-size: 12px;
            color: rgba(0, 0, 0, 0.85);
            letter-spacing: 2px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: center;
          }
        }
      }
      /deep/ .ant-carousel .slick-dots-bottom {
        height: 12px;
        bottom: -42px;
      }
      /deep/ .ant-carousel .slick-dots li button {
        height: 12px;
        width: 12px;
        border-radius: 12px;
        background: #d8d8d8;
        opacity: 1;
      }
      /deep/ .ant-carousel .slick-dots li.slick-active button {
        background-color: #005de2;
      }
    }
    .artist-tab {
      margin-top: 36px;
      margin-bottom: 28px;
      display: flex;
      justify-content: center;
      .tab-container {
        display: flex;
        border-bottom: 2px solid #979797;
        .tab-item {
          font-weight: 600;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.85);
          letter-spacing: 2px;
          cursor: pointer;
          position: relative;
          margin-left: 24px;
          .tab-line {
            position: absolute;
            bottom: -2px;
            left: 0;
            width: 32px;
            height: 2px;
            background-color: #ff8807;
            z-index: 10;
          }
        }
        .tab-item:first-child {
          margin: 0;
        }
      }
    }
    .artist-page {
      box-sizing: border-box;
      padding: 0 73px 108px 73px;
      display: grid !important;
      grid-template-columns: repeat(6, 1fr);
      grid-row-gap: 52px;
      grid-column-gap: 40px;
      .artist-item {
        width: 124px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .item-img {
          width: 124px;
          height: 124px;
          background: #d8d8d8;
          border: 1px solid #979797;
          border-radius: 124px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 124px;
          }
        }
        .item-img:hover {
          border: 2px solid #ff8807;
        }
        .item-name {
          margin-top: 12px;
          margin-bottom: 4px;
          font-weight: 600;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.85);
          letter-spacing: 2px;
        }
        .item-job-title {
          width: 100%;
          font-weight: 300;
          font-size: 10px;
          color: rgba(0, 0, 0, 0.85);
          letter-spacing: 1px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: center;
        }
      }
    }
  }
  .footer {
    display: flex;
    justify-content: center;
    margin-bottom: 72px;
    .footer-container {
      width: 886px;
      border-top: 1px solid rgba(0, 0, 0, 0.12);
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      .footer-top {
        margin-top: 25px;
        font-weight: 400;
        font-size: 10px;
        color: rgba(0, 0, 0, 0.85);
        span {
          margin-left: 12px;
        }
      }
      .footer-bottom {
        font-weight: 400;
        font-size: 10px;
        color: rgba(0, 0, 0, 0.45);
        margin-top: 16px;
      }
      .icon-logo {
        width: 42px;
        height: 24px;
        position: absolute;
        top: 25px;
        left: 89px;
      }
    }
  }
  .side-operation {
    width: 40px;
    height: 172px;
    background: #ffffff;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.06);
    border-radius: 24px;
    position: fixed;
    right: 80px;
    bottom: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 18px 0;
    .side-icon {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
    .qrcode {
      background-color: #fff;
      background-clip: padding-box;
      border-radius: 4px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.15) \9;
      position: fixed;
      right: 140px;
      bottom: 120px;
      .qrcode-container {
        position: relative;
        box-sizing: border-box;
        padding: 15px 33px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .qrcode-top {
          display: flex;
          .qrcode-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            .qrcode-item-name {
              font-weight: 600;
              font-size: 10px;
              color: rgba(0, 0, 0, 0.85);
              margin-bottom: 5px;
            }
            img {
              width: 61px;
              height: 61px;
            }
          }
          .qrcode-item:first-child {
            margin-right: 40px;
          }
        }
        .qrcode-tip {
          font-weight: 400;
          font-size: 9px;
          color: rgba(0, 0, 0, 0.85);
          margin-top: 6px;
        }
        .qrcode-arrow {
          position: absolute;
          display: block;
          width: 8.48528137px;
          height: 8.48528137px;
          background: transparent;
          border-style: solid;
          border-width: 4.24264069px;
          right: -4px;
          border-top-color: #fff;
          border-right-color: #fff;
          border-bottom-color: transparent;
          border-left-color: transparent;
          box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
          top: 50%;
          transform: translateY(-50%) rotate(45deg);
        }
      }
    }
  }
  .person-info {
    width: 312px;
    display: flex;
    flex-direction: column;
    .person-info-name {
      font-weight: 600;
      font-size: 20px;
      color: rgba(0, 0, 0, 0.85);
      letter-spacing: 1px;
    }
    .person-info-job {
      font-weight: 500;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
      letter-spacing: 1px;
      margin: 8px 0 16px 0;
      line-height: 20px;
    }
    .person-experience-label {
      font-weight: 500;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
      letter-spacing: 1px;
      margin-bottom: 12px;
    }
    .person-experience-content {
      font-weight: 400;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
      line-height: 20px;
      letter-spacing: 1px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
}
</style>
