<template>
  <div>
    <div class="page-container">
      <div class="com">
        <div class="logo-container">
          <img src="../assets/logo.png" alt="" />
          <div class="name">
            <div class="name_z">
              中国少年儿童造型艺术学会
            </div>
            <div class="name_e">
              Chinese Plastic Arts Association for Children
            </div>
          </div>
        </div>
        <div class="nav-content">
          <div
            class="item"
            :class="selectNav === item.id ? 'active' : ''"
            v-for="(item, index) in menuList"
            :key="index"
            @click="onSelectNav(item)"
          >
            {{ item.menuName }}
          </div>
        </div>
        <div class="search">
          <img src="../assets/search.png" alt="" />
        </div>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "HomeNav",
  data() {
    return {
      selectNav: "",
      menuList: [
        {
          menuName: "首页",
          id: "Home"
        },
        {
          menuName: "学会概括",
          id: "Summarize"
        },
        {
          menuName: "资讯",
          id: "Information"
        },
        {
          menuName: "赛事",
          id: "GamePage"
        },
        {
          menuName: "公益课",
          id: "PublicWelfareClassList"
        },
        {
          menuName: "学艺鉴赏",
          id: "ArtAppreciation"
        },
        {
          menuName: "智库",
          id: "IntelligenceLibrary"
        },
        {
          menuName: "会员",
          id: "MemberPage"
        },
        {
          menuName: "联系",
          id: "ContactPage"
        }
      ]
    };
  },
  computed: {
    ...mapState(["selectNavItem"])
  },
  watch: {
    selectNavItem(newVal) {
      this.selectNav = newVal.id;
    }
  },
  created() {
    let vuex = localStorage.getItem("vuex")
      ? JSON.parse(localStorage.getItem("vuex"))
      : {};
    if (vuex.selectNavItem) {
      this.$store.dispatch("setSelectNav", {
        name: vuex.selectNavItem.name,
        id: vuex.selectNavItem.id
      });
    } else {
      this.$store.dispatch("setSelectNav", {
        name: "首页",
        id: "Home"
      });
    }
  },
  mounted() {
    this.selectNav = this.$store.state.selectNavItem.id;
  },
  methods: {
    onSelectNav(item) {
      this.$store.dispatch("setSelectNav", {
        name: item.menuName,
        id: item.id
      });
      this.$router.push({
        name: item.id
      });
    }
  }
};
</script>

<style scoped lang="less">
.page-container {
  position: fixed;
  z-index: 88;
  left: 0;
  top: 0;
  right: 0;
  background-color: #fff;
  height: 72px;
  .com {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 72px;
    width: 1200px;
    margin: 0 auto;
    .logo-container {
      display: flex;
      align-items: center;
      img {
        width: 64px;
        height: 48px;
      }
      .name {
        margin-left: 16px;
        .name_z {
          font-size: 19px;
          color: rgba(0, 0, 0, 0.85);
        }
        .name_e {
          color: rgba(0, 0, 0, 0.65);
          font-size: 11px;
        }
      }
    }
    .nav-content {
      display: flex;
      align-items: center;
      margin-left: 100px;
      font-size: 14px;
      .item {
        margin-right: 42px;
        cursor: pointer;
        &:hover {
          color: #08a782;
          font-weight: 600;
        }
        &.active {
          color: #08a782;
          font-weight: 600;
          position: relative;
          &:after {
            content: "";
            position: absolute;
            bottom: -5px;
            left: 0;
            background-color: #08a782;
            height: 3px;
            right: 0;
          }
        }
      }
    }
    .search {
      img {
        width: 16px;
        height: 16px;
        cursor: pointer;
      }
    }
  }
}
</style>
